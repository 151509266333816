import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';
import addTargetBlankToAnchors from '../../utils/addTargetBlankToAnchors';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';

const BlogPost = ({ pageContext }) => {
  const { post } = pageContext;

  useEffect(() => {
    addTargetBlankToAnchors('w-richtext');
  }, []);

  return (
    <div>
      <Helmet>
        <title>{post.fields.title}</title>
        <meta property="og:title" content={post.fields.title} />
        <meta property="og:description" content={post.fields.description} />
        <meta name="description" content={post.fields.description} />
        <meta
          property="og:image"
          content={post.fields.heroImage.fields.file.url}
        />
        <meta property="og:image:width" content="1500" />
        <meta property="og:image:height" content="945" />
        <meta
          property="og:url"
          content={`https://eddisrupt.com/blog/${post.fields.category.fields.slug}/${post.fields.slug}/`}
        />
        <meta name="twitter:title" content={post.fields.title} />
        <meta name="twitter:description" content={post.fields.description} />
        <meta
          name="twitter:image"
          content={post.fields.heroImage.fields.file.url}
        />
        <meta name="twitter:card" content={post.fields.title} />
      </Helmet>

      <div className="blog-post-header">
        <img
          src={post.fields.heroImage.fields.file.url}
          alt=""
          sizes="100vw"
          className="blog-post-hero-image"
        />
        <div className="blog-post-hero-title-container">
          <div className="container">
            <div className="content-width-extra-large">
              <div className="blog-post-meta">
                <div
                  style={{ backgroundColor: '#392396' }}
                  className="badge blog-badge w-inline-block"
                >
                  <div style={{ color: 'hsla(0, 0.00%, 100.00%, 1.00)' }}>
                    {post.fields.category.fields.title}
                  </div>
                </div>
                <div className="small-text">
                  {moment(post.fields.publishDate).format('MMM D, YYYY')}
                </div>
              </div>
              <h1>{post.fields.title}</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="container">
          <div className="content-width-extra-large align-center article-width">
            <div className="article w-richtext">
              {getArticleBlocks({ markdown: post.fields.body })}
            </div>
          </div>
          <div className="blog-post-horizontal-rule"></div>
        </div>
      </div>
    </div>
  );
};

export default BlogPost;

const getArticleBlocks = ({ markdown }) => {
  if (markdown.includes('~~~js')) {
    const markdownSplited = markdown.split('~~~js');

    let counter = true;

    const markdownSections = markdownSplited.map((markdownSection) => {
      counter = !counter;

      if (counter === false) {
        return <ReactMarkdown children={markdownSection}></ReactMarkdown>;
      } else {
        return (
          <>
            <SyntaxHighlighter language="javascript" style={dark}>
              {markdownSection}
            </SyntaxHighlighter>
            <br />
          </>
        );
      }
    });

    return markdownSections;
  } else {
    return <ReactMarkdown children={markdown}></ReactMarkdown>;
  }
};
