import React, { useContext } from 'react';
import { Link } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import moment from 'moment';
import { LangContext } from '../../containers/context/LangContext';
import { getLangPrefix } from '../../utils';

const Blog = ({ pageContext }) => {
  const { articles, categories } = pageContext;

  const { translations, lang } = useContext(LangContext);

  return (
    <>
      <div className="page-contents">
        <div className="section bg-primary-2">
          <div className="container">
            <div className="content-width-extra-large page-title">
              <div className="text-pill-wrapper">
                {/* <div className="text-pill animate-in-first">
                  <h6>Blog</h6>
                </div> */}
              </div>
              <h1 className="display-heading-2 animate-in-second">Blog</h1>
              <div className="large-text animate-in-third">
                "I could either watch it happen or be a part of it" - Elon Musk
              </div>
              <Grid
                container
                style={{
                  width: '100%',
                  marginTop: '2em',
                  justifyContent: 'center'
                }}
                spacing={2}
              >
                {categories.map((category) => (
                  <Grid item>
                    <Link
                      to={`${getLangPrefix({ lang })}/blog/${category.slug}`}
                    >
                      <Chip
                        label={category.title}
                        style={{
                          color: 'white',
                          backgroundColor: '#392396',
                          fontFamily: 'IBM Plex Mono, sans-serif',
                          textTransform: 'capitalize'
                        }}
                        clickable
                      />
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        </div>
        <div className="featured-blog-section bg-primary-2">
          <div className="container">
            <div className="w-dyn-list">
              <div
                role="list"
                className="featured-blog-posts overlap animate-in-fourth w-dyn-items"
              >
                {articles
                  .sort((a, b) =>
                    b.fields.publishDate.localeCompare(a.fields.publishDate)
                  )
                  .map((article, index) =>
                    article.fields.featured ? (
                      <Link
                        to={`/blog/${article.fields.category.fields.slug}/${article.fields.slug}/`}
                        className="blog-link-tile w-inline-block"
                      >
                        <div
                          key={article.sys.id}
                          role="listitem"
                          className="w-dyn-item"
                        >
                          <img
                            src={`${article.fields.heroImage.fields.file.url}`}
                            style={{ objectFit: 'cover' }}
                            className="blog-tile-image"
                          />
                          <div className="blog-tile-title">
                            <div className="blog-post-meta">
                              <div
                                style={{
                                  backgroundColor:
                                    index === 0 ? '#ffd803' : '#bae8e8'
                                }}
                                className="badge blog-badge"
                              >
                                <div
                                  style={{
                                    color: '#141414',
                                    fontSize: 'clamp(0px, 3vw, 14px)',
                                    lineHeight: '12px'
                                  }}
                                >
                                  {article.fields.category.fields.title}
                                </div>
                              </div>
                              <div
                                className="small-text"
                                style={{ fontSize: 'clamp(0px, 4vw, 16px)' }}
                              >
                                {moment(article.fields.publishDate).format(
                                  'MMM D, YYYY'
                                )}
                              </div>
                            </div>
                            <h3
                              className="no-space-bottom"
                              style={{ fontSize: 'clamp(0px, 5vw, 28px)' }}
                            >
                              {article.fields.title}
                            </h3>
                          </div>
                          <div className="tile-scrim"></div>
                        </div>
                      </Link>
                    ) : null
                  )}
              </div>
            </div>
          </div>
          <img
            src="https://assets.website-files.com/5e58549685a7904fa5912b7c/5e7c33d0b72a822ea1c5a47b_Doodle-7-Dark.svg"
            alt=""
            className="doodle-left-4 animate-in-last"
          />
        </div>
        <div className="section blog-posts-section">
          <div className="container">
            <div className="section-title align-left">
              <h2 className="large-heading no-space-bottom">
                {translations.articlesTitle}
              </h2>
            </div>
            <div className="w-dyn-list">
              <div role="list" className="blog-grid w-dyn-items">
                {articles
                  .sort((a, b) =>
                    b.fields.publishDate.localeCompare(a.fields.publishDate)
                  )
                  .map((article) => (
                    <div
                      key={article.sys.id}
                      role="listitem"
                      className="w-dyn-item"
                    >
                      <Link
                        to={`/blog/${article.fields.category.fields.slug}/${article.fields.slug}`}
                        className="w-inline-block"
                        style={{ marginBottom: '1em' }}
                      >
                        <img
                          src={article.fields.heroImage.fields.file.url}
                          style={{ objectFit: 'cover' }}
                          width="100%"
                          height="235px"
                          className="rounded-corners blog-image"
                        />
                      </Link>
                      <div className="blog-post-meta">
                        <Link
                          to={`/blog/${article.fields.category.fields.slug}/`}
                          className="badge blog-badge w-inline-block"
                          style={{
                            backgroundColor: '#392396',
                            height: 'auto',
                            minHeight: '30px'
                          }}
                        >
                          <div
                            style={{
                              color: 'hsla(0, 0.00%, 100.00%, 1.00)',
                              fontSize: 'clamp(0px, 3vw, 14px)',
                              lineHeight: '16px',
                              padding: '4px 0'
                            }}
                          >
                            {article.fields.category.fields.title}
                          </div>
                        </Link>
                        <div
                          className="small-text"
                          style={{ fontSize: 'clamp(0px, 4vw, 16px)' }}
                        >
                          {moment(article.fields.publishDate).format(
                            'MMM D, YYYY'
                          )}
                        </div>
                      </div>
                      <Link
                        to={`/blog/${article.fields.category.fields.slug}/${article.fields.slug}/`}
                        className="w-inline-block"
                      >
                        <h4 style={{ fontSize: 'clamp(0px, 5vw, 24px)' }}>
                          {article.fields.title}
                        </h4>
                      </Link>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
