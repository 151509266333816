import React, { useContext } from 'react';
import { LangContext } from '../../containers/context/LangContext';

const Ral = () => {
  const { translations } = useContext(LangContext);

  return (
    <>
      <div className="section bg-gray-1">
        <div className="container">
          <div className="content-width-extra-large page-title">
            <h1 className="display-heading-2">{translations['pageTitle']}</h1>
            <div>
              <div className="text-pill bg-primary-4">
                <h6>{translations['lastUpdate']}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="container">
          <div className="content-width-extra-large align-center article-width">
            {/* Lets keep dangerouslySetInnerHTML for now, this code is hardocoded in the gatsby code, not external */}
            <div
              className="article w-richtext"
              dangerouslySetInnerHTML={{
                __html: translations['bodyText']
              }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ral;
