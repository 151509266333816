import React, { useContext } from 'react';
import { ContactUsForm } from '../../components';
import useSetState from '../../utils/useSetState';
import { cloneDeep } from 'lodash';
import onSubmitHandler from './utils/onSubmitHandler';
import { FeedbackContext } from '../context/FeedbackContext/FeedbackContext';
import { LangContext } from '../../containers/context/LangContext';

const ContactUsContainer = () => {
  const { translations } = useContext(LangContext);

  const [state, setState] = useSetState({ email: '', message: '', name: '' });

  const { setFeedbackState } = useContext(FeedbackContext);

  const onChangeField = ({ propName }, e) => {
    const stateClone = cloneDeep(state);
    stateClone[propName] = e.target.value;
    setState(stateClone);
  };

  return (
    <div>
      <ContactUsForm
        translations={translations}
        state={state}
        onSubmitHandler={onSubmitHandler.bind(null, {
          setFeedbackState,
          state,
          setState,
          translations
        })}
        onChangeField={onChangeField}
      />
    </div>
  );
};

export default ContactUsContainer;
