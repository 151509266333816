export const asyncSetItem = (key, val) => {
  const job = ({ resolve }) => {
    const result = wasFinished();
    if (result) {
      return resolve(true);
    } else {
      setTimeout(() => {
        job({ resolve });
      }, 0);
    }
  };

  const wasFinished = () => {
    const result = localStorage.getItem(key) == val;
    console.log('The new key', key, 'equals', val, '- changed: ', result);
    return result;
  };

  const prom = new Promise((resolve) => {
    localStorage.setItem(key, val);
    job({ resolve });
  });

  return prom;
};
