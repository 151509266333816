import { useState, useContext, useRef, useEffect } from 'react';
import { LangContext } from '../../../containers/context/LangContext';
import { useForm } from '../../../utils/hooks';
import useSubscribe from '../../../utils/useSubscribe';

export const useLetsTalkForm = () => {
  const [submitState, setSubmitState] = useState({
    success: false
  });

  const { setFormField, values } = useForm({
    fields: {
      email: '',
      accept_terms: false
    }
  });

  const [contentHeight, setContentHeight] = useState(null);

  const modalContentRef = useRef(null);

  useEffect(() => {
    setContentHeight(modalContentRef?.current?.offsetHeight);
  }, []);

  const { translations } = useContext(LangContext);

  const { subscribe } = useSubscribe();

  return {
    formValues: values,
    submitState,
    setFormField,
    translations,
    modalContentRef,
    contentHeight,
    onSubmitHandler: subscribe.bind(null, {
      state: values,
      onSuccess: () => {
        setSubmitState({ ...submitState, success: true });
      },
      type: 'lets_talk'
    })
  };
};
