import { getUserLocale } from 'get-user-locale';
import { navigate } from 'gatsby';

const redirecIfPt = ({ lang, location }) => {
  const userBrowserLang = getUserLocale();
  const browserlangLowercase = userBrowserLang?.toLowerCase();
  // ed_lang can be empty
  const pickedLang = localStorage.getItem('ed_lang')?.toLowerCase();

  // não redireciona: se a lang do site já for PT nao se deve redirecionar para pt
  // não redireciona: se o user já tiver definido a lang alguma vez via selector para algo diferente de pt
  // se browser nao for pt, mas por alguma razão o user seja pt e tenha definido como pt, então ele deve redireciionar

  if (
    lang !== 'pt' &&
    (!pickedLang || pickedLang == 'pt') &&
    (browserlangLowercase == 'pt' ||
      browserlangLowercase == 'br' ||
      browserlangLowercase == 'pt-br' ||
      browserlangLowercase == 'pt-pt')
  ) {
    // needs to add params so it redirects to the correct section after this lang redirect
    navigate('/pt' + location.search + location.hash);
  }
};

export default redirecIfPt;
