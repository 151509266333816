const mentors = [
  {
    name: 'Pedro Bruno',
    img: '/images/pedro_bruno_pic.jpg',
    description: 'Software Engineer @ Mindera',
    slug: 'pedro-bruno',
    techs: ['javascript', 'CSS', 'React.JS'],
    bio: {
      pt: '----',
      en: "----"
    },
    mentoring: 6,
    location: {
      pt: 'Lisboa, Portugal',
      en: 'Lisbon, Portugal'
    },
    experience: 15,
    workplace: 'Hala Systems',
    title: 'Software Engineer',
    id: 1
  },
  {
    name: 'William Ribeiro',
    img: '/images/william.jpg',
    description: 'Software Engineer @ Hala Systems',
    slug: 'william-ribeiro',
    techs: ['javascript', 'CSS', 'React.JS'],
    bio: {
      pt: 'Sou o William, sou bueda cool yoo',
      en: "I'm William and I am bueda cool yoo"
    },
    mentoring: 6,
    location: {
      pt: 'Lisboa, Portugal',
      en: 'Lisbon, Portugal'
    },
    experience: 15,
    workplace: 'Hala Systems',
    title: 'Software Engineer',
    id: 1
  },
  {
    name: 'Mário Prada',
    img: '/images/mario-prada.jpg',
    description: 'Front-End Developer @ Proxify',
    slug: 'mario-prada',
    techs: ['javascript', 'CSS', 'React.JS'],
    bio: {
      pt: 'Sou o William, sou bueda cool yoo',
      en: "I'm William and I am bueda cool yoo"
    },
    joined: '01/05/2021',
    mentoring: 56,
    location: {
      pt: 'Lisboa, Portugal',
      en: 'Lisbon, Portugal'
    },
    experience: 4,
    workplace: 'Proxify',
    title: 'front-End Developer',
    id: 2
  },
  {
    name: 'João Gomes',
    img: '/images/joao-full.jpg',
    description: 'Full-Stack Developer @ Visual Nuts',
    slug: 'joao-gomes',
    techs: ['javascript', 'CSS', 'Node.JS'],
    bio: {
      pt: 'Sou o William, sou bueda cool yoo',
      en: "I'm William and I am bueda cool yoo"
    },
    joined: '01/06/2021',
    mentoring: 13,
    location: {
      pt: 'Açores, Portugal',
      en: 'Azores, Portugal'
    },
    experience: 5,
    workplace: 'Visual Nuts',
    title: 'Full-Stack Developer',
    id: 3
  },
  {
    name: 'André Santos',
    img: '/images/andre-santos.jpg',
    description: 'Sofware Engineer @ Siemens',
    slug: 'andre-santos',
    techs: ['javascript', 'CSS', 'React.JS', 'Node.JS'],
    bio: {
      pt: 'Sou o William, sou bueda cool yoo',
      en: "I'm William and I am bueda cool yoo"
    },
    joined: '16/08/2021',
    mentoring: 24,
    location: {
      pt: 'Lisboa, Portugal',
      en: 'Lisbon, Portugal'
    },
    experience: 6,
    workplace: 'Siemens',
    title: 'Sofware Engineer',
    id: 4
  }
  // {
  //   name: 'Pedro Bruno',
  //   img: '/images/pedro_bruno_pic.jpg',
  //   description: 'Sofware Developer @ Proxify',
  //   slug: 'pedro-bruno',
  //   techs: ['javascript', 'CSS', 'React.JS', 'Node.JS'],
  //   bio: {
  //     pt: 'Sou o William, sou bueda cool yoo',
  //     en: "I'm William and I am bueda cool yoo",
  //   },
  //   joined: '01/01/2021',
  //   mentoring: 34,
  //   location: {
  //     pt: 'Lisboa, Portugal',
  //     en: 'Lisbon, Portugal',
  //   },
  //   experience: 5,
  //   workplace: 'Proxify',
  //   title: 'Sofware Developer',
  //   id: 5
  // }
];

module.exports = mentors;
