import queryString from 'query-string';

export const getUpdatedQueryString = ({ newParams }) => {
  const currentQueryString = window.location.search;

  const paramsObj = queryString.parse(currentQueryString);

  const newParamsObj = { ...paramsObj, ...newParams };

  return '?' + queryString.stringify(newParamsObj);
};
