import React from 'react';
import Navbar from '../../containers/Navbar';
import Footer from '../../containers/Footer';
import { CookiesBar } from '../../components';

const WebsiteWrapper = ({
  children,
  isNavBgWhite,
  isBlogArticle,
  location
}) => {
  return (
    <>
      <Navbar
        isBgWhite={isNavBgWhite}
        isBlogArticle={isBlogArticle}
        location={location}
      />
      {children}
      <Footer />
      <CookiesBar />
    </>
  );
};

export default WebsiteWrapper;
