import { useState } from 'react';

const useForm = ({ fields }) => {
  const [formData, setFormData] = useState({
    fields
  });

  const setFormField = (fields) => {
    setFormData({
      ...formData,
      fields: { ...formData.fields, ...fields }
    });
  };

  return { setFormField, values: formData.fields };
};

export { useForm };
