import React, { useContext } from 'react';
import { Link } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import moment from 'moment';
import { LangContext } from '../../containers/context/LangContext';
import { getLangPrefix } from '../../utils';

const BlogCategory = ({ pageContext }) => {
  const { category, articles, categories } = pageContext;

  const { translations, lang } = useContext(LangContext);

  return (
    <>
      <div class="page-contents">
        <div class="section bg-primary-2">
          <div class="container">
            <div class="content-width-extra-large page-title">
              <div class="text-pill-wrapper">
                {/* <div class="text-pill animate-in-first">
                  <h6>Blog</h6>
                </div> */}
              </div>
              <h1 class="display-heading-2 animate-in-second">
                {category.title}
              </h1>
              <div class="large-text animate-in-third">
                "I could either watch it happen or be a part of it" - Elon Musk
              </div>
              <Grid
                container
                style={{
                  width: '100%',
                  marginTop: '2em',
                  justifyContent: 'center'
                }}
                spacing={2}
              >
                {categories
                  .filter((cat) => cat.title !== category.title)
                  .map((category) => (
                    <Grid item>
                      <Link
                        to={`${getLangPrefix({ lang })}/blog/${category.slug}`}
                      >
                        <Chip
                          label={category.title}
                          style={{
                            color: 'white',
                            backgroundColor: '#392396',
                            fontFamily: 'IBM Plex Mono, sans-serif',
                            textTransform: 'capitalize'
                          }}
                          clickable
                        />
                      </Link>
                    </Grid>
                  ))}
              </Grid>
            </div>
          </div>
        </div>

        <div
          className="section blog-posts-section"
          style={{ paddingTop: '75px' }}
        >
          <div className="container">
            <div className="section-title align-left">
              <h2 className="large-heading no-space-bottom">
                {translations.articlesTitle}
              </h2>
            </div>

            <div className="w-dyn-list">
              <div role="list" className="blog-grid w-dyn-items">
                {articles
                  .sort((a, b) =>
                    b.fields.publishDate.localeCompare(a.fields.publishDate)
                  )
                  .filter(
                    (article) =>
                      article.fields.category.fields.title === category.title
                  )
                  .map((article) => (
                    <div
                      key={article.sys.id}
                      role="listitem"
                      className="w-dyn-item"
                    >
                      <Link
                        to={`/blog/${article.fields.category.fields.slug}/${article.fields.slug}`}
                        className="w-inline-block"
                        style={{ marginBottom: '1em' }}
                      >
                        <img
                          src={article.fields.heroImage.fields.file.url}
                          style={{ objectFit: 'cover' }}
                          width="100%"
                          height="235px"
                          className="rounded-corners blog-image"
                        />
                      </Link>
                      <div className="blog-post-meta">
                        <Link
                          to={`/blog/${article.fields.category.fields.slug}/`}
                          className="badge blog-badge w-inline-block"
                          style={{
                            backgroundColor: '#392396',
                            height: 'auto',
                            minHeight: '30px'
                          }}
                        >
                          <div
                            style={{
                              color: 'hsla(0, 0.00%, 100.00%, 1.00)',
                              fontSize: 'clamp(0px, 3vw, 14px)',
                              lineHeight: '16px',
                              padding: '4px 0'
                            }}
                          >
                            {article.fields.category.fields.title}
                          </div>
                        </Link>
                        <div
                          className="small-text"
                          style={{ fontSize: 'clamp(0px, 4vw, 16px)' }}
                        >
                          {moment(article.fields.publishDate).format(
                            'MMM D, YYYY'
                          )}
                        </div>
                      </div>
                      <Link
                        to={`/blog/${article.fields.category.fields.slug}/${article.fields.slug}/`}
                        className="w-inline-block"
                      >
                        <h4 style={{ fontSize: 'clamp(0px, 5vw, 24px)' }}>
                          {article.fields.title}
                        </h4>
                      </Link>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogCategory;
