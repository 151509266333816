import axios from 'axios';
import { api_base_url } from '../../../data/client';
import is_email_valid from '../../../utils/is_email_valid';

const onSubmitHandler = async (
  { state, setFeedbackState, setState, translations },
  e
) => {
  const translatedErrors = translations.general.errors;

  try {
    e.preventDefault();

    const { email } = state;

    if (!email) {
      setFeedbackState({
        isActive: true,
        message: translatedErrors.provideEmail,
        isError: true,
        isLoading: false
      });
    } else if (!is_email_valid(email)) {
      setFeedbackState({
        isActive: true,
        message: translatedErrors.provideValidEmail,
        isError: true,
        isLoading: false
      });
    } else {
      setFeedbackState({
        isActive: true,
        isError: false,
        isLoading: true
      });

      const res = await axios({
        url: `${api_base_url}/emails`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=utf-8'
        },
        data: JSON.stringify({ ...state })
      });

      if (!res.data.error) {
        setFeedbackState({
          isActive: true,
          message: translatedErrors.submitted,
          isError: false,
          isLoading: false
        });

        // clear form
        setState({ email: '', message: '', name: '' });
      } else {
        setFeedbackState({
          isActive: true,
          message: translatedErrors.unexpectedError,
          isError: true,
          isLoading: false
        });
      }
    }
  } catch (err) {
    setFeedbackState({
      isActive: true,
      message: translatedErrors.unexpectedError,
      isError: true,
      isLoading: false
    });
  }
};

export default onSubmitHandler;
