import React, { useContext, useEffect } from 'react';
import { redirecIfPt } from '../../utils';
import {
  YhellowButton,
  ClickPopUp,
  MentorCardsList,
  TestimonialCardList,
  DailyScheduleTable,
  CheckItem
} from '../../components';
import ContactUsContainer from '../../containers/ContactUsContainer/ContactUsContainer';
import FAQsContainer from '../../containers/FAQsContainer/FAQsContainer';
import { LangContext } from '../../containers/context/LangContext';
import styles from './Home.module.css';
import GetCurriculumContainer from '../../containers/GetCurriculumContainer/GetCurriculumContainer';
import { ApplyProgramContainer } from '../../containers';
import { LetsTalkForm } from '../../organisms';

const mentors = require('../../data/shared/mentors');

const Home = ({ location }) => {
  const { translations, lang } = useContext(LangContext);

  useEffect(() => {
    redirecIfPt({ lang, location });
    // addDriftScript();
  }, []);

  return (
    <>
      <div className="page-contents">
        <div className="pageContentBody">
          {/* Hero */}

          <div className="section">
            <div
              className={
                styles.heroBanner__grid +
                ' w-layout-grid grid-halves overlap-grid-halves'
              }
            >
              <div
                id="w-node-aaeb817b-ef49-894a-a370-c097d2c04f1e-19901cfa"
                className={styles.heroBanner_leftContainer + ' container'}
              >
                <div
                  className={
                    styles.heroBanner_leftContent +
                    ' content-width-large align-center'
                  }
                >
                  <h1 className="display-heading-2 heroBanner__title">
                    {translations['mainTitle']}
                  </h1>
                  <div className="large-text heroBanner__text">
                    {translations['mainSubtitle']}
                    {/* <div
                      className={styles.heroDateBadge + ' text-pill-wrapper'}
                    >
                      <div className="text-pill">
                        <span>{translations.nextBatchChip}</span>
                      </div>
                    </div> */}
                  </div>
                  <div className="buttons-row space-top">
                    <ClickPopUp ModalComponent={<ApplyProgramContainer />}>
                      <button
                        className="button button-large bg-primary-4 w-inline-block"
                        style={{ width: '100%' }}
                      >
                        <div>{translations['mainLeftBtnText']}</div>
                      </button>
                    </ClickPopUp>
                    <div className="button-row-last">
                      <ClickPopUp ModalComponent={<LetsTalkForm />}>
                        <button
                          className="button button-outline button-large w-inline-block"
                          style={{ width: '100%' }}
                        >
                          <div>{translations['mainRightBtnText']}</div>
                        </button>
                      </ClickPopUp>
                    </div>
                  </div>
                </div>
              </div>
              <img
                src="/images/react-mentorship-zool-call.png"
                id="w-node-aaeb817b-ef49-894a-a370-c097d2c04f2c-19901cfa"
                alt="Eddisrupt Bootcamp"
                className={styles.heroBanner__img + ' right-aligned-hero-image'}
              />
            </div>
            <div className="hero-background-color-block"></div>
          </div>

          {/* Alumni Companies section */}

          <div className={styles.alumniLogosSection}>
            <div id="companies" className="anchorMagnet"></div>
            <div className={styles.alumniLogosSectionText + ' flex alignX'}>
              {translations['almuniLogosText']}
            </div>
            <div className={styles.alumniLogosSectionInner}>
              <img src="/images/companies/talkdesk.png" />
              <img src="/images/companies/accenture.png" />
              <img src="/images/companies/informatec.png" />
              <img src="/images/companies/deloitte.png" />
              <img src="/images/companies/altar.jpeg" />
              <img src="/images/companies/bold_by_devoteam.png" />
              <img src="/images/companies/critical-techworks.png" />
              <img src="/images/companies/visor-ai.png" />
              <img src="/images/companies/siemens.png" />
              <img src="/images/companies/proxify.png" />
              <img src="/images/companies/bool-software.png" />
              <img src="/images/companies/altran.png" />
              <img
                style={{ padding: '13px' }}
                src="/images/companies/sky.png"
              />
              <img src="/images/companies/diconium.png" />
            </div>
          </div>

          {/* STATS SECTION */}

          <div className="section">
            <div className="container">
              <div className={styles.stats__statsBoxes}>
                <div
                  className={
                    styles.stats__statBox + ' ' + styles.stats__boxLeft
                  }
                >
                  <p className={styles.stats__statBoxTitle}>5000+</p>
                  <div className={styles.stats__statBoxText}>
                    {translations.section1.stat1Text}
                  </div>
                </div>
                <div
                  className={
                    styles.stats__statBox + ' ' + styles.stats__boxMiddle
                  }
                >
                  <p className={styles.stats__statBoxTitle}>60+</p>
                  <div className={styles.stats__statBoxText}>
                    {translations.section1.stat2Text}
                  </div>
                </div>
                <div
                  className={
                    styles.stats__statBox + ' ' + styles.stats__boxRight
                  }
                >
                  <p className={styles.stats__statBoxTitle}>91%</p>
                  <div className={styles.stats__statBoxText}>
                    {translations.section1.stat3Text}
                  </div>
                </div>
              </div>
              {/* <div className={styles.stats__featuredBox}>
                <ul className={styles.stats__featuredList}>
                  <li>
                    <div className={styles.stats__featuredBoxTitle}>
                      <img
                        className={styles.stats__featuredBoxIcon}
                        src="/images/money_mouth_icon.png"
                      />{' '}
                      {translations.section1.bullet1Title}
                    </div>
                    <div className={styles.stats__featuredBoxText}>
                      {translations.section1.bullet1Text}
                    </div>
                  </li>
                  <li>
                    <div className={styles.stats__featuredBoxTitle}>
                      <img
                        className={styles.stats__featuredBoxIcon}
                        src="/images/briefcase.png"
                      />{' '}
                      {translations.section1.bullet2Title}
                    </div>
                    <div className={styles.stats__featuredBoxText}>
                      {translations.section1.bullet2Text}
                    </div>
                  </li>
                  <li>
                    <div className={styles.stats__featuredBoxTitle}>
                      <img
                        className={styles.stats__featuredBoxIcon}
                        src="/images/wizard2.png"
                      />{' '}
                      {translations.section1.bullet3Title}
                    </div>
                    <div className={styles.stats__featuredBoxText}>
                      {translations.section1.bullet3Text}
                    </div>
                  </li>
                  <li>
                    <div className={styles.stats__featuredBoxTitle}>
                      <img
                        className={styles.stats__featuredBoxIcon}
                        src="/images/teacher.png"
                      />{' '}
                      {translations.section1.bullet4Title}
                    </div>
                    <div className={styles.stats__featuredBoxText}>
                      {translations.section1.bullet4Text}
                    </div>
                  </li>
                </ul>
              </div> */}
              {/* <div className="cards-grid-thirds">
                <div className="feature-third">
                  <div className="numbered-list-item-box large bg-primary-2">
                    <div className="numbered-list-item-number">1</div>
                  </div>
                  <h4>{translations['section1Item1Title']}</h4>
                  <div className="large-text">
                    {translations['section1Item1Text']}
                  </div>
                </div>
                <div className="feature-third">
                  <div className="numbered-list-item-box large bg-primary-4">
                    <div className="numbered-list-item-number">2</div>
                  </div>
                  <h4>{translations['section1Item2Title']}</h4>
                  <div className="large-text">
                    {translations['section1Item2Text']}
                  </div>
                </div>
                <div className="feature-third">
                  <div className="numbered-list-item-box large bg-primary-1">
                    <div className="numbered-list-item-number">3</div>
                  </div>
                  <h4>{translations['section1Item3Title']}</h4>
                  <div className="large-text">
                    {translations['section1Item3Text']}
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          {/* SECTION: Why developers, the perks, the opportunity */}

          <div className="section no-top-space">
            <div className="container">
              <div className="w-layout-grid grid-halves">
                <div
                  className="content-width-large align-center"
                  style={{ height: '100%', margin: 'auto' }}
                >
                  <img
                    style={{
                      borderRadius: '5px',
                      height: '100%',
                      objectFit: 'cover'
                    }}
                    src="/images/eddisrupt-why-become-a-developer.jpg"
                    alt="Eddisrupt - Why become a software developer"
                  />
                </div>
                <div
                  className={
                    styles.whyDeveloper__infoBox + ' content-width-medium'
                  }
                >
                  <h3 className="large-heading space-bottom-large">
                    {translations.whyDeveloperSection.title}
                  </h3>
                  <div className="w-layout-grid text-grid-vertical">
                    <div>
                      <div
                        className={
                          styles.titledList__titleWrap + ' flex alignY'
                        }
                      >
                        <h4 className={styles.titledList__title}>
                          {translations.whyDeveloperSection.bulletTitle1}
                        </h4>
                        <img
                          className={styles.titledList__titleImg}
                          src="/images/icons/salary.png"
                        />
                      </div>
                      <div className="large-text">
                        {translations.whyDeveloperSection.bulletText1}
                      </div>
                    </div>
                    <div>
                      <div
                        className={
                          styles.titledList__titleWrap + ' flex alignY'
                        }
                      >
                        <h4 className={styles.titledList__title}>
                          {translations.whyDeveloperSection.bulletTitle2}
                        </h4>
                        <img
                          className={styles.titledList__titleImg}
                          src="/images/icons/creativity.png"
                        />
                      </div>
                      <div className="large-text">
                        {translations.whyDeveloperSection.bulletText2}
                      </div>
                    </div>
                    <div>
                      <div className={styles.titledList__titleWrap + ' flex'}>
                        <h4 className={styles.titledList__title}>
                          {translations.whyDeveloperSection.bulletTitle3}
                        </h4>
                        <img
                          className={styles.titledList__titleImg}
                          src="/images/icons/world.png"
                        />
                      </div>
                      <div className="large-text">
                        {translations.whyDeveloperSection.bulletText3}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* MEDIA SECTION */}

          <div
            id="media"
            className={styles.mediaSection + ' section overlap-next-section'}
          >
            <div className="container">
              <div className="rounded-large bg-gray-1 large-padding media-box">
                <div className="justify-content-center align-content-center">
                  <div className="section-title mediaTitleBox">
                    <h3 className="medium-heading center-text">
                      {translations.mediaSection.title}
                    </h3>
                  </div>
                  <div className="logo-container space-bottom-extra-large media__logos">
                    <img
                      src="/images/media/uniarea-logo.png"
                      alt="uniarea eddisrupt"
                      className={styles.adjustLogo1 + ' brand-logo'}
                    />
                    <img
                      src="/images/media/Dinheiro_vivo_logo.jpg"
                      alt="dinheiro vivo eddisrupt"
                      className={styles.adjustLogo2 + ' brand-logo'}
                    />
                    <img
                      src="/images/media/shifter.png"
                      alt="shifter eddisrupt"
                      className={styles.adjustLogo3 + ' brand-logo'}
                    />
                    <img
                      src="/images/media/marketeer-logo.png"
                      alt="link to leaders eddisrupt"
                      className="brand-logo"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*  */}

          <div
            id="bootcamp" /* id for navigation scroll, dont remove  */
            className={styles.solidTrackRecordSection + ' section bg-primary-1'}
          >
            <div className="container">
              <div
                className={
                  styles.followPathBox + ' grid-halves flexColInvert alignYTop'
                }
              >
                <div>
                  <div className="image-with-caption-wrapper">
                    <img
                      src="/images/live_classes/live_bootcamp.jpg"
                      width="684"
                      alt="Eddisrupt live class"
                      className="rounded-large"
                    />
                  </div>
                </div>
                <div>
                  <div className="text-pill-wrapper">
                    <div className="text-pill bg-primary-4">
                      <h6>Bootcamp</h6>
                    </div>
                  </div>
                  <h4 className="large-heading">
                    {translations.solidTrackRecordSection.section3Part1Title}
                  </h4>
                  <div className="large-text">
                    {translations.solidTrackRecordSection.section3Part1Text}
                  </div>
                </div>
              </div>
              <div className="grid-halves alignYTop">
                <div>
                  <div className="text-pill-wrapper">
                    <div className="text-pill bg-primary-4">
                      <h6>Bootcamp</h6>
                    </div>
                  </div>
                  <h4 className="large-heading">
                    {translations.solidTrackRecordSection.section3Part2Title}
                  </h4>
                  <div className="large-text">
                    {translations.solidTrackRecordSection.section3Part2Text}
                  </div>
                </div>
                <div className="image-with-caption-wrapper">
                  <img
                    src="/images/mentor.jpg"
                    width="684"
                    alt=""
                    className="rounded-large"
                  />
                </div>
              </div>
            </div>
          </div>

          {/*  */}
          <div className="section bg-transparent">
            <div className="container">
              <div className="grid-halves alignYTop flexColInvert">
                <DailyScheduleTable />
                <div>
                  <div className="text-pill-wrapper">
                    <div className="text-pill">
                      <h6>{translations.howItWorksSection.chip}</h6>
                    </div>
                  </div>
                  <h4 className="large-heading">
                    {translations.howItWorksSection.title}
                  </h4>
                  <div className={styles.howItWorksSection__bullets}>
                    <CheckItem
                      text={translations.howItWorksSection.bullet1}
                      checkBgColor="#BAE8E8"
                    />
                    <CheckItem
                      text={translations.howItWorksSection.bullet2}
                      checkBgColor="#BAE8E8"
                    />
                    <CheckItem
                      text={translations.howItWorksSection.bullet3}
                      checkBgColor="#BAE8E8"
                    />
                    <CheckItem
                      text={translations.howItWorksSection.bullet4}
                      checkBgColor="#BAE8E8"
                    />
                  </div>
                  <p className="large-text">
                    {/* {translations.howItWorksSection.text1}
                    <br />
                    <br /> */}
                    {translations.howItWorksSection.text2}
                    <br />
                    <br />
                    {translations.howItWorksSection.text3}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Curriculum Section */}

          <div className={styles.greenBg}>
            <div id="curriculum" className="anchorMagnet"></div>
            <div className="section bg-transparent">
              <div className="container">
                <div
                  className={
                    styles.pathsSectionGrid +
                    ' alignYTop grid-halves flexColInvert'
                  }
                >
                  <div className={styles.mentorcampCardsContainer}>
                    <MentorcampPathCard
                      title="Front-End Developer React.js"
                      items={[
                        { label: translations.curriculumSection.bullet1 },
                        { label: translations.curriculumSection.bullet2 },
                        ,
                        { label: translations.curriculumSection.bullet3 },
                        ,
                        { label: translations.curriculumSection.bullet4 },
                        ,
                        { label: translations.curriculumSection.bullet5 }
                      ]}
                      imgUrl="/images/technologies/react.png"
                    />
                  </div>

                  <div>
                    <div className="text-pill-wrapper">
                      <div className="text-pill">
                        <h6>{translations.curriculumSection.chip}</h6>
                      </div>
                    </div>
                    <h4 className="large-heading">
                      {translations.curriculumSection.title}
                    </h4>
                    <p className="large-text">
                      {translations.curriculumSection.text1}
                      <br />
                      <br />
                      {translations.curriculumSection.text2}
                    </p>
                    <div className={styles.courseTechnologies}>
                      <img src="/images/technologies/react.png" />
                      <img src="/images/technologies/javascript-js.png" />
                      <img src="/images/technologies/html.png" />
                      <img src="/images/technologies/css.png" />
                      {/* <img src="/images/technologies/jQuery.png" /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Build amazing things */}
          <div
            className={styles.greenBg + ' section no-bottom-space no-top-space'}
          >
            <div className={styles.buildAmThings__container + ' container'}>
              <div className="alignYTop grid-halves flexColInvert">
                <div
                  className={
                    styles.buildAmThings__imgContainer +
                    ' image-with-caption-wrapper'
                  }
                >
                  <img
                    src="/images/build-cool-apps.gif"
                    width="684"
                    alt="Eddisrupt build amazing things"
                    className={styles.buildAmThings__img + ' rounded-large'}
                  />
                </div>
                <div>
                  <h3 className="large-heading">
                    {translations.buildAmThingsSection.title}
                  </h3>
                  <p className="large-text">
                    {translations.buildAmThingsSection.text}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Team section */}

          <div className={styles.greenBg}>
            <div className="section bg-transparent">
              <div className="container">
                <div
                  className={
                    styles.mentorsSectionGrid + ' grid-halves alignYTop'
                  }
                >
                  <div>
                    <div className="text-pill-wrapper">
                      <div className="text-pill">
                        <h6>{translations.teamSection.chip}</h6>
                      </div>
                    </div>
                    <h4 className="large-heading">
                      {translations.teamSection.title}
                    </h4>
                    <p className="noMargin large-text">
                      {translations.teamSection.text1}
                      <br />
                      <br />
                      {translations.teamSection.text2}
                    </p>
                  </div>
                  <div className={styles.mentorsContainer}>
                    <MentorCardsList mentors={mentors} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* PRICE Section  */}

          <div className="section no-bottom-space" id="course-price">
            <div className="container">
              <div className="w-layout-grid grid-halves">
                <div className="content-width-large align-center">
                  <img
                    src="/images/bootcamp-price.png"
                    alt="Price comparison eddisrupt bootcamp"
                  />
                </div>
                <div className="content-width-medium">
                  <h3 className="large-heading space-bottom-large">
                    {translations.priceSection.title}
                  </h3>
                  <div className="w-layout-grid text-grid-vertical">
                    <div>
                      <h4>{translations.priceSection.part1Title}</h4>
                      <p className="large-text">
                        {translations.priceSection.part1Text}
                      </p>
                    </div>
                    {/* <div>
                      <h4>Recupera o investimento rápido</h4>
                      <p className="large-text">
                        Com um salário de entrada entre 1000 e 1300 euros e
                        rápida progressão de carreira, o valor do curso é um
                        investimento que poderás recuperar rápido
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* TESTIMONALS Section */}

          <div id="testimonials" className="section no-bottom-space">
            <div className="container">
              <div className="page-title flex flexCol alignY centerText">
                <div className="text-pill-wrapper">
                  <div className={styles.testimonialsChip + ' text-pill'}>
                    <h6>{translations.section9.chipText}</h6>
                  </div>
                </div>
                <h3 className="display-heading-2">
                  {translations.section9.title}
                </h3>
                <div className="large-text">
                  {translations.section9.subTitle}
                </div>
              </div>
            </div>
          </div>
          <div className="section no-space-top">
            <div className="container">
              <div className={styles.testimonialsFirstRow + ' quotes-row'}>
                {TestimonialCardList({
                  cards: translations.section9.testimonials
                }).slice(0, 3)}
              </div>
              <div className="quotes-row">
                {TestimonialCardList({
                  cards: translations.section9.testimonials
                }).slice(3, 6)}
              </div>
            </div>
          </div>

          {/*  */}
          {/* CTA Section */}

          <div className="section no-space-top bg-transparent">
            <div className="container">
              <div className="grid-halves alignYTop flexColInvert">
                <div className={styles.ctaSection__imgWrap}>
                  <img
                    src="/images/what-are-you-waiting-for.jpg"
                    width="684"
                    alt="Book your seat on the coding bootcamp eddisrupt"
                    className={styles.ctaSection__img + ' rounded-large'}
                  />
                </div>

                <div>
                  <h4 className="medium-heading">
                    {translations.ctaSection.title}
                  </h4>
                  <p className="mb-20 large-text">
                    {translations.ctaSection.text1}
                    {/* <br />
                    <br />
                    {translations.ctaSection.text2} */}
                  </p>
                  {/* <div className={styles.ctaSectionItems}>
                    <CheckItem
                      text={translations.ctaSection.bullet1}
                      checkBgColor="#BAE8E8"
                    />
                    <CheckItem
                      text={translations.ctaSection.bullet2}
                      checkBgColor="#BAE8E8"
                    />
                    <CheckItem
                      text={translations.ctaSection.bullet3}
                      checkBgColor="#BAE8E8"
                    />
                  </div> */}

                  <div className="buttons-row space-top">
                    <ClickPopUp ModalComponent={<ApplyProgramContainer />}>
                      <YhellowButton>
                        {translations['mainLeftBtnText']}
                      </YhellowButton>
                    </ClickPopUp>
                    <div className="button-row-last">
                      <ClickPopUp ModalComponent={<LetsTalkForm />}>
                        <div className="button button-outline button-large w-inline-block">
                          <div>{translations['mainRightBtnText']}</div>
                        </div>
                      </ClickPopUp>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Contact */}

          <div id="contact" className="anchorMagnet"></div>
          <div className="container">
            <div className={styles.contactSection + ' section no-space-top'}>
              <ContactUsContainer />
            </div>
          </div>

          {/*  */}
          {/* FAQ */}

          {/* this div is here for scroll to effect */}
          <div id="faq" className="anchorMagnet"></div>
          <div className="section no-space-top">
            <div className="container">
              <div className={styles.faqSection}>
                <h1>FAQ</h1>
                <FAQsContainer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;

const MentorcampPathCard = ({ imgUrl, items }) => {
  const { translations } = useContext(LangContext);

  return (
    <div className={styles.mentorcampPathCard}>
      <h3 className={styles.mentorcampPathCard__title}>
        Front-End Developer React.js
      </h3>
      <div className="flex alignX">
        <img className={styles.mentorcampPathCard__img} src={imgUrl} />
      </div>
      <div className={styles.mentorcampPathCard__itemsList}>
        {items.map((item) => {
          return (
            <CheckItem
              text={item.label}
              checkBgColor="#BAE8E8"
              classNameBullet={styles.mentorcampPathCard__bullet}
            />
          );
        })}
      </div>
      <div className={'flex ' + styles.mentorcampPathCard__cta}>
        <img
          className="imgIcon smallIcon"
          src="/images/icons/pointing-right.png"
        />{' '}
        <ClickPopUp ModalComponent={<GetCurriculumContainer />}>
          <span>{translations.curriculumSection.downloadCVText}</span>
        </ClickPopUp>
      </div>
    </div>
  );
};
