import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { LangContext } from './context/LangContext';

const Footer = () => {
  const { translations } = useContext(LangContext);

  return (
    <div className="footer bg-gray-1">
      <div style={{ height: '150px' }} className="container">
        <div className="footer-with-large-links">
          <div className="social_media_box">
            <div className="social_media_icon">
              <a target="_blank" href="https://www.facebook.com/eddisrupt/">
                <img src="/images/icons/facebook-app-logo.svg" />
              </a>
            </div>
            <div className="social_media_icon">
              <a
                target="_blank"
                href="https://www.instagram.com/eddisrupt/?hl=pt"
              >
                <img src="/images/icons/instagram.svg" />
              </a>
            </div>
            <div className="social_media_icon">
              <a target="_blank" href="https://www.meetup.com/pt-BR/eddisrupt/">
                <img src="/images/icons/meetup-logo.svg" />
              </a>
            </div>
          </div>
          <div className="footer-large-link-list">
            <Link to={translations.general['termsAndConditionsLink']}>
              <h6 className="footer-link-list-heading text-white">
                {translations.general.footer['footerItem1Text']}
                <br />
              </h6>
            </Link>
            <Link to={translations.general['privacyPolicyLink']}>
              <h6 className="footer-link-list-heading text-white">
                {translations.general.footer['footerItem3Text']}
                <br />
              </h6>
            </Link>
          </div>
          <div className="footer-links-grid-4-column">
            <div className="footer-link-list"></div>
            <div className="footer-link-list">
              <Link to={translations.general.footer['footerItem2Link']}>
                <h6 className="footer-link-list-heading text-white">
                  {translations.general.footer['footerItem2Text']}
                  <br />
                </h6>
              </Link>
              <Link to={translations.general.footer['footerItem4Link']}>
                <h6 className="footer-link-list-heading text-white">
                  {translations.general.footer['footerItem4Text']}
                  <br />
                </h6>
              </Link>
            </div>
          </div>
        </div>

        <div className="copyright_box">© Copyright Eddisrupt</div>
      </div>
      <img
        src="../images/lines-13-white.svg"
        alt=""
        className="footer-background-image"
      />
    </div>
  );
};

export default Footer;
