import { navigate } from 'gatsby';
import { useForm } from '../../../utils/hooks';
import { getUpdatedQueryString } from '../../../utils/url';
import useSubscribe from '../../../utils/useSubscribe';

export const useLP = () => {
  const { setFormField, values } = useForm({
    fields: {
      email: '',
      accept_terms: false,
      success: false,
      country: ''
    }
  });

  const { subscribe } = useSubscribe();

  const onSubmitSuccess = () => {
    try {
      setFormField({ success: true });

      const isNationalityPt = values?.country?.toLowerCase() == 'pt';

      const newQueryString = isNationalityPt
        ? getUpdatedQueryString({
            newParams: { signup_guide_lp: true }
          })
        : '';

      navigate(window.location.pathname + newQueryString + '#form_area');
    } catch (err) {
      //
    }
  };

  return {
    setFormField,
    values,
    onSubmit: subscribe.bind(null, {
      state: values,
      onSuccess: onSubmitSuccess,
      type: 'lp_workshop'
    })
  };
};
