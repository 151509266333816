import React from 'react';
import Button from '../../components/Button';
import styles from './styles.module.css';

export const EmailCourseBeginLayout = ({}) => {
  return (
    <div>
      <div className="section">
        <div
          className={
            styles.container + ' w-layout-grid grid-halves overlap-grid-halves'
          }
        >
          <div
            id="w-node-aaeb817b-ef49-894a-a370-c097d2c04f1e-19901cfa"
            className="container"
          >
            <div className="content-width-large align-center">
              <h1 className="display-heading-2 animate-in-first">
                {/* Build a website for your dev-first tech product. */}
                Welcome to the course 🎉
              </h1>
              <div className="large-text animate-in-second">
                {/* Devkit is a complete UI Kit for SaaS, Technology and
                Multi-Purpose Business websites. */}
                Watch the intro video on the right to get you started!
              </div>

              <p className={styles.successMessage}>
                ✅ After you watch the video you'll receive the lessons in your
                email 👈👈
              </p>
              <div className={styles.ctaBtn__wrap}>
                <Button size="small">
                  <a target="_blank" href="/">
                    View Bootcamp
                  </a>
                </Button>
              </div>
            </div>
          </div>
          <div
            style={{
              padding: '56.25% 0 0 0',
              position: 'relative',
              border: '1px solid lightgrey',
              borderRadius: '6px'
            }}
          >
            <iframe
              src="https://player.vimeo.com/video/677138521?h=2c6aeb156b&badge=0&autopause=0&autoplay=1&loop=1&player_id=0&app_id=58479"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%'
              }}
              title="Tech Career Eddisrupt Workshop"
            ></iframe>
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
        <div className="hero-background-color-block"></div>
      </div>
    </div>
  );
};
