import React from 'react';
import { Button, Spinner } from '../../components';
import styles from './DashCommunity.module.css';

const DashCommunity = ({ discordData }) => {
  return (
    <>
      {discordData.isLoading ? (
        <Spinner />
      ) : !discordData.hasAccess ? (
        'You dont have access to this resource'
      ) : (
        <div className={styles.mainBox}>
          <div>
            <h2 className={styles.mainTitle + ' mainTitle'}>
              Join the Party{' '}
              <img
                className="imgIcon mediumIcon"
                src="/images/icons/party.png"
              />
            </h2>
            <p className={styles.introBoxParagraph + ' mainSubtitle'}>
              Join our community on Discord, where you can share your knowledge
              and get help with your challenges
            </p>
            <div className={styles.btnBox}>
              <a href={discordData.url} target="_blank">
                <Button size="small">Enter Discord</Button>
              </a>
            </div>
          </div>
          <div>
            <img
              className={styles.bigImg}
              src="/images/discord_community.jpg"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default DashCommunity;
