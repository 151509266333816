import React from 'react';
import styles from './styles.module.css';
import { Checkbox } from '@material-ui/core';
import { useLP } from './hooks';
import Carrousel from '../../components/Slider';
import { CountryInput } from '../../components';

const LeadMagnetLPlayout = ({ translations, type }) => {
  const { setFormField, onSubmit, values } = useLP();

  const customGridStyles = type == 'lp2' ? styles.heroGrid + ' ' : '';
  const customSectionStyles = type == 'lp2' ? styles.lpSection + ' ' : '';
  const customContainerStyles =
    type == 'lp2' ? styles.infoHeroBlock + ' ' : styles.containerLp1 + ' ';
  const customColorBgStyles =
    type == 'lp2' ? styles.heroBgColorBlock + ' ' : '';

  return (
    <div>
      <div className={customSectionStyles + 'section'}>
        <div
          className={
            customGridStyles + 'w-layout-grid grid-halves overlap-grid-halves'
          }
        >
          <div
            id="w-node-aaeb817b-ef49-894a-a370-c097d2c04f1e-19901cfa"
            className={customContainerStyles + 'container'}
          >
            <div
              className={
                styles.contentBox + ' content-width-large align-center'
              }
            >
              <h1 className="display-heading-2 animate-in-first">
                {type == 'lp2' ? '' : <>{translations.title} 👀</>}
              </h1>
              <div className="large-text animate-in-second">
                {translations.description}
              </div>
              <div className="buttons-row space-top">
                <div
                  id="form_area" /* id needed to redirect to feedback area when user converts - do not remove */
                  className="anchorMagnet"
                />
                {values.success ? (
                  <p className={styles.successMessage}>
                    👉👉 ✅ {translations.successMessage} 💪
                  </p>
                ) : (
                  <div className="subscribe-form align-center animate-in-second w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      className={styles.form + ' form-row'}
                      onSubmit={(e) => {
                        e.preventDefault();
                        onSubmit();
                      }}
                    >
                      <div className={styles.inputFields}>
                        <input
                          type="email"
                          className="form-input form-input-large w-input"
                          maxlength="256"
                          name="email"
                          data-name="email"
                          placeholder={translations.emailPlaceholder}
                          value={values.email}
                          onChange={(e) =>
                            setFormField({
                              email: e.target.value
                            })
                          }
                        />
                        <div className={styles.countryInputWrap}>
                          <CountryInput
                            value={values.country}
                            onChange={(e) =>
                              setFormField({
                                country: e.target.value
                              })
                            }
                            defaultPlaceholder={
                              translations.general.nationalityDefaultPlaceholder
                            }
                          />
                        </div>
                      </div>
                      <input
                        type="submit"
                        value={translations.btnText}
                        data-wait="Please wait..."
                        className={
                          styles.ctaButton +
                          ' button button-large form-row-button w-button'
                        }
                      />
                    </form>

                    <div className={styles.terms}>
                      <Checkbox
                        color="primary"
                        onChange={() =>
                          setFormField({
                            accept_terms: !values.accept_terms
                          })
                        }
                        checked={values.accept_terms}
                      />
                      <span>
                        {translations.general.termsCheckbox.termsText1}{' '}
                        <a
                          className="link"
                          target="_blank"
                          href={translations.general.termsAndConditionsLink}
                        >
                          {translations.general.termsCheckbox.termsText2}
                        </a>{' '}
                        {translations.general.termsCheckbox.termsText3}{' '}
                        <a
                          target="_blank"
                          className="link"
                          href={translations.general.privacyPolicyLink}
                        >
                          {translations.general.termsCheckbox.termsText4}
                        </a>
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {type == 'lp2' ? (
            <div className={styles.sliderWrap}>
              <Carrousel slidesToShow={1}>
                {[0, 1, 2, 3, 4, 5, 6, 8].map((ele, i) => {
                  return (
                    <div>
                      <img
                        className={styles.sliderImg}
                        src={`/images/lp-guide/slides/${i + 1}.jpg`}
                      />
                    </div>
                  );
                })}
              </Carrousel>
            </div>
          ) : (
            <img
              src="/images/dev-professional.jpg"
              id="w-node-aaeb817b-ef49-894a-a370-c097d2c04f2c-19901cfa"
              alt="work in tech culture"
              className={styles.mainImg + ' right-aligned-hero-image'}
            />
          )}
        </div>
        <div
          className={customColorBgStyles + 'hero-background-color-block'}
        ></div>
      </div>

      {/* ///////// */}
      {/* ///////// */}
      {/* ///////// */}
      {/* ///////// */}

      <div className="section">
        <div className="container">
          <div className="cards-grid-thirds">
            <div className="feature-third">
              {/* <img
                src="https://assets.website-files.com/5e58549685a7904fa5912b7c/5e7822c6e5ba0e14fb9c8c07_icon-gallery-purple.svg"
                alt=""
                className="space-bottom"
              /> */}
              <img
                src="/images/money-maker.png"
                alt=""
                className={styles.bulletImg + ' space-bottom'}
              />
              <h4>{translations.bullet1_title}</h4>
              <div>
                {/* Bunc id tincidunt duis faucibus urna adipiscing. Id lorem diam
                egestas vel facilisis fames. */}
                {translations.bullet1_text} 💰
              </div>
            </div>
            <div className="feature-third">
              {/* <img
                src="https://assets.website-files.com/5e58549685a7904fa5912b7c/5e7822c69105facad1e620af_icon-id-purple.svg"
                alt=""
                className="space-bottom"
              /> */}
              <img
                src="/images/tech-team.jpg"
                alt="tech team career development"
                className={styles.bulletImg + ' space-bottom'}
              />
              <h4>{translations.bullet2_title}</h4>
              <div>{translations.bullet2_text} 🌎</div>
            </div>
            <div className="feature-third">
              {/* <img
                src="https://assets.website-files.com/5e58549685a7904fa5912b7c/5e7822c7bc626208df8c30c9_icon-switcher-purple.svg"
                alt=""
                className="space-bottom"
              /> */}
              <img
                src="/images/work_from_anywhere.png"
                alt=""
                className={styles.bulletImg + ' space-bottom'}
              />
              <h4>{translations.bullet3_title}</h4>
              <div>{translations.bullet3_text} 🕳🐇</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadMagnetLPlayout;
