import React, { useContext } from 'react';
import { FAQs } from '../../components';
import { LangContext } from '../../containers/context/LangContext';

const FAQsContainer = () => {
  const { translations } = useContext(LangContext);

  return <FAQs FAQsArray={translations.faqs} />;
};

export default FAQsContainer;
