import React, { useEffect } from 'react';
import useSetState from '../../utils/useSetState';
import { ApplyProgram } from '../../parts';
import useSubscribe from '../../utils/useSubscribe';

export const ApplyProgramContainer = () => {
  const [state, setState] = useSetState({
    email: '',
    firstName: '',
    lastName: '',
    phoneObj: {
      number: '',
      countryCode: 'GB',
      phonePrefix: '+44'
    },
    country: '',
    accept_terms: false,
    success: false,
    canDoFullTime: ''
  });

  const onChangeField = ({ fieldName }, e) => {
    const mergeObj = {};
    mergeObj[fieldName] = e.target.value;
    setState({ ...state, ...mergeObj });
  };

  useEffect(() => {
    // tag manager tracking
    // need to find a better way to track this
    // navigate('?opened-apply-modal=true');
  }, []);

  const { subscribe } = useSubscribe();

  return (
    <ApplyProgram
      state={state}
      setState={setState}
      onChangeField={onChangeField}
      onSubmitHandler={subscribe.bind(null, {
        state,
        onSuccess: () => setState({ success: true }),
        type: 'apply'
      })}
    />
  );
};
