import React, { useState, useContext } from 'react';
import { Link, navigate } from 'gatsby';
import { Drawer } from '@material-ui/core';
import { Popper, ClickPopUp } from '../../components';
import { ApplyProgramContainer } from '../../containers';
import { LangContext } from '../context/LangContext';
import styles from './Navbar.module.css';
import { flagsData } from '../../data/client';
import { asyncSetItem } from '../../utils/localStorage';

const Navbar = ({ isBgWhite, isBlogArticle, location }) => {
  const { translations, lang } = useContext(LangContext);

  const [state, setState] = useState({ is_drawer_open: false });

  const [langPickerOptions] = useState(
    getLangPickerOptions({ lang, translations })
  );

  const onLangChange = async (newLang) => {
    try {
      const navigateTo = newLang.link;

      const location = window.location;

      if (navigateTo) {
        await asyncSetItem('ed_lang', newLang.value);
        // navigate and mantain all query params and hash params
        navigate(navigateTo + location.search + location.hash);
      }
    } catch (err) {
      // nothing for now
    }
  };

  return (
    <div
      className={
        'navbar-container' +
        (isBgWhite ? ' bg-white' : ' bg-primary-3') +
        (isBlogArticle ? ' nav-spacing-article' : '')
      }
    >
      <div className="navbar-bg"></div>
      <div
        data-collapse="medium"
        data-animation="default"
        data-duration="12"
        data-easing="linear"
        data-easing2="linear"
        role="banner"
        className="navbar w-nav"
      >
        <div className="container nav-bar-container centered-links-nav">
          <Link
            to={translations.general.navbar.logoLink}
            className="w-nav-brand"
          >
            <img src="/images/eddisrupt.svg" alt="eddisrupt" />
          </Link>
          <nav
            role="navigation"
            id="w-node-999791252559-91252555"
            className="nav-menu-links w-nav-menu"
          >
            {translations.general.navbar.navItems.map((item, index) => (
              <Link key={index} className="nav-menu-link" to={item.to}>
                {item.title}
              </Link>
            ))}
          </nav>

          <div
            id="w-node-999791252586-91252555"
            className="nav-button-container"
          >
            {translations.langUrls ? (
              <div className={styles.langSelectWrap}>
                <Popper
                  width="7.9375rem"
                  top="45px"
                  TriggerComponent={
                    <img
                      className={styles.langPickerIcon}
                      src="/images/icons/language-choice.png"
                    />
                  }
                >
                  <div className={styles.langModalContent}>
                    {langPickerOptions.map((lang) => {
                      return (
                        <div
                          className={styles.langModalItem}
                          onClick={onLangChange.bind(null, lang)}
                        >
                          {/* <Flag langCode={lang.value} /> */}
                          {lang.label}
                        </div>
                      );
                    })}
                  </div>
                </Popper>
              </div>
            ) : null}

            {location.pathname == '/' || location.pathname == '/pt' ? (
              <ClickPopUp ModalComponent={<ApplyProgramContainer />}>
                <div className="button button-outline nav-bar-button w-button">
                  {translations.general.navbar.navButtonText}
                </div>
              </ClickPopUp>
            ) : (
              <a
                href={translations.general.navbar.nonBootcampLpCtaLink}
                target="_blank"
              >
                <div className="button button-outline nav-bar-button w-button">
                  {translations.general.navbar.nonBootcampLpCta}
                </div>
              </a>
            )}
            <div
              onClick={setState.bind(null, { is_drawer_open: true })}
              className="menu-button w-nav-button"
            >
              <img src="/images/icon-menu.svg" alt="" />
            </div>
          </div>
          <Drawer
            open={state.is_drawer_open}
            onClose={setState.bind(null, { is_drawer_open: false })}
          >
            <div style={{ width: '200px', padding: '30px' }}>
              {translations.general.navbar.navItems.map((item, index) => {
                return (
                  <Link
                    key={index}
                    onClick={setState.bind(null, { is_drawer_open: false })}
                    className="nav-menu-link"
                    style={{ marginBottom: '10px' }}
                    to={item.to}
                  >
                    {item.title}
                  </Link>
                );
              })}
            </div>
          </Drawer>
        </div>
      </div>
    </div>
  );
};

export default Navbar;

const getLangPickerOptions = ({ lang, translations }) => {
  if (translations.langUrls) {
    const langPickerOptions = [
      {
        value: lang,
        label: lang == 'en' ? 'English' : 'Portuguese' /* hardcoded for now */
      }
    ];

    translations.langUrls.forEach((langUrl) => {
      const lang = langUrl.lang;
      langPickerOptions.push({
        value: lang,
        label: lang == 'en' ? 'English' : 'Portuguese', // hardcoded for now
        link: langUrl.link
      });
    });
    return langPickerOptions;
  }
};

const Flag = ({ langCode }) => {
  return <img className={styles.langFlag} src={flagsData[langCode]} />;
};
