import axios from 'axios';
import { api_base_url } from '../data/client';
import is_email_valid from './is_email_valid';
import { navigate } from 'gatsby';
import { FeedbackContext } from '../containers/context/FeedbackContext/FeedbackContext';
import { useContext } from 'react';
import { LangContext } from '../containers/context/LangContext';
import { getSources } from './tracking';

const useSubscribe = () => {
  const { translations, lang } = useContext(LangContext);
  const { setFeedbackState } = useContext(FeedbackContext);

  const translatedErrors = translations.general.errors;

  const subscribe = async ({ state, onSuccess, type }) => {
    try {
      // for apply only

      if (type == 'apply') {
        if (!state.firstName) {
          return setFeedbackState({
            isActive: true,
            isError: true,
            isLoading: false,
            message: translatedErrors.provideFirstName
          });
        } else if (!state.lastName) {
          return setFeedbackState({
            isActive: true,
            isError: true,
            isLoading: false,
            message: translatedErrors.provideLastName
          });
        } else if (!state.canDoFullTime) {
          return setFeedbackState({
            isActive: true,
            isError: true,
            isLoading: false,
            message: translatedErrors.provideAvailability
          });
        }
      }

      // ------

      if (type == 'apply' || type == 'lp_workshop') {
        if (!state.country) {
          return setFeedbackState({
            isActive: true,
            isError: true,
            isLoading: false,
            message: translatedErrors.provideNationality
          });
        }
      }

      if (!state.email) {
        setFeedbackState({
          isActive: true,
          isError: true,
          isLoading: false,
          message: translatedErrors.provideEmail
        });
      } else if (!is_email_valid(state.email)) {
        setFeedbackState({
          isActive: true,
          isError: true,
          isLoading: false,
          message: translatedErrors.provideValidEmail
        });
      } else if (!state.accept_terms) {
        setFeedbackState({
          isActive: true,
          isError: true,
          isLoading: false,
          message: translatedErrors.acceptTerms
        });
      } else {
        setFeedbackState({
          isActive: true,
          isError: false,
          isLoading: true
        });

        //// end of validations

        const trackingSources = getSources();

        const res = await axios({
          url: `${api_base_url}/newsletter/subscription/`,
          method: 'POST',
          headers: {
            'Content-type': 'application/json; charset=utf-8'
          },
          data: JSON.stringify({
            ...state,
            email_address: state.email,
            type,
            trackingSources,
            lang
          })
        });

        if (!res.data.error) {
          // LETS activate this only for type == 'apply', because it scrolls to the top, and we dont want to hurt bootcamp conversion after user signs on other forms

          // let's also tell facebok algo that we want to only count and optimize for pt and canDoFullTime
          if (
            type == 'apply' &&
            state.canDoFullTime == 'true' &&
            state?.country?.toLowerCase() == 'pt'
          ) {
            const baseRoute = lang == 'pt' ? '/pt' : '/';
            navigate(baseRoute + '?signup-success=true'); // Needed for pixel to measure conversion events
          }

          onSuccess({ success: true });

          setFeedbackState({
            isActive: true,
            isError: false,
            isLoading: false,
            message: translatedErrors.submitted
          });
        } else {
          setFeedbackState({
            isActive: true,
            isError: true,
            isLoading: false,
            message: translatedErrors.unexpectedError
          });
        }
      }
    } catch (err) {
      setFeedbackState({
        isActive: true,
        isError: true,
        isLoading: false,
        message: translatedErrors.unexpectedError
      });
    }
  };

  return { subscribe };
};

export default useSubscribe;

// Phone is deactivated for now

// else if (!state.phoneObj.number) {
//   return setFeedbackState({
//     isActive: true,
//     isError: true,
//     isLoading: false,
//     message: translatedErrors.providePhoneNumber
//   });
// } else if (!state.phoneObj.isValid) {
//   return setFeedbackState({
//     isActive: true,
//     isError: true,
//     isLoading: false,
//     message: translatedErrors.provideValidPhoneNumber
//   });
// } else if (!state.phoneObj.phonePrefix) {
//   return setFeedbackState({
//     isActive: true,
//     isError: true,
//     isLoading: false,
//     message: translatedErrors.providePhonePrefix
//   });
// } else if (!state.phoneObj.phonePrefix) {
//   return setFeedbackState({
//     isActive: true,
//     isError: true,
//     isLoading: false,
//     message: translatedErrors.providePhonePrefix
//   });
// }
