import React from 'react';
import styles from './LetsTalkForm.module.css';
import { Checkbox } from '@material-ui/core';
import { useLetsTalkForm } from './hooks';

export const LetsTalkForm = () => {
  const {
    translations,
    formValues,
    setFormField,
    onSubmitHandler,
    submitState,
    modalContentRef,
    contentHeight
  } = useLetsTalkForm();

  return (
    <div className={styles.modal_main_box}>
      {submitState.success ? (
        <div
          className={styles.successContainer}
          style={{
            height: contentHeight + 'px'
          }}
        >
          <span>
            {translations.general.talkWithUsPopup.positiveFeedbackText}{' '}
            <span style={{ fontSize: '21px', paddingTop: '2px' }}>📧</span>
          </span>
        </div>
      ) : (
        <div
          className={styles.fields_box}
          id="email-form"
          name="email-form"
          data-name="Email Form"
          ref={modalContentRef}
        >
          <h5 className={styles.title}>
            {translations.general.talkWithUsPopup.title}
          </h5>
          <p>{translations.general.talkWithUsPopup.description}</p>
          <div className={styles.main_fields_box}>
            <input
              onChange={(e) => {
                setFormField({ email: e.target.value });
              }}
              value={formValues.email}
              type="email"
              className={
                'form-input form-input-large w-input ' + styles.input_field
              }
              name="email"
              placeholder="Email"
            />
            <input
              onClick={onSubmitHandler}
              type="submit"
              value={translations.general.talkWithUsPopup.submitBtnText}
              className={
                'button button-large form-row-button w-button ' +
                styles.input_field
              }
            />
          </div>
          <div className={styles.checkOption}>
            <Checkbox
              color="primary"
              checked={formValues.accept_terms}
              onChange={setFormField.bind(null, {
                accept_terms: !formValues.accept_terms
              })}
            />
            <span className={styles.terms}>
              {translations.general.termsCheckbox.termsText1}{' '}
              <a
                className={styles.link}
                target="_blank"
                href={translations.general.termsAndConditionsLink}
              >
                {translations.general.termsCheckbox.termsText2}
              </a>{' '}
              {translations.general.termsCheckbox.termsText3}{' '}
              <a
                target="_blank"
                className={styles.link}
                href={translations.general.privacyPolicyLink}
              >
                {translations.general.termsCheckbox.termsText4}
              </a>
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
