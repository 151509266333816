import React, { useContext } from 'react';
import styles from './ApplyProgram.module.css';
import { Checkbox } from '@material-ui/core';
import { LangContext } from '../../containers/context/LangContext';
import { CountryInput } from '../../components';
// import { PhoneInput } from '../../components';

export const ApplyProgram = ({
  state,
  setState = () => {},
  onSubmitHandler = () => {},
  onChangeField
}) => {
  const { translations } = useContext(LangContext);

  return (
    <div className={styles.modal_main_box + ' customScrollBar1'}>
      {state.success ? (
        <div className={styles.feedbackBox}>
          <p>
            {translations.general.applyProgramPopup.positiveFeedbackText}{' '}
            <span style={{ fontSize: '21px', paddingTop: '2px' }}>📧</span>
          </p>
        </div>
      ) : (
        <div
          style={{ margin: 0 }}
          className="subscribe-form align-center animate-in-second"
        >
          <div
            className={styles.fields_box}
            id="email-form"
            name="email-form"
            data-name="Email Form"
          >
            <h5 className={styles.title}>
              {translations.general.applyProgramPopup.title}
            </h5>
            <p> {translations.general.applyProgramPopup.text}</p>
            <div className={styles.main_fields_box}>
              <input
                onChange={onChangeField.bind(null, { fieldName: 'email' })}
                value={state.email}
                type="email"
                className={
                  'form-input form-input-large w-input ' + styles.input_field
                }
                name="email"
                placeholder="Email"
              />
              <input
                onChange={onChangeField.bind(null, { fieldName: 'firstName' })}
                value={state.firstName}
                type="text"
                className={
                  'form-input form-input-large w-input ' + styles.input_field
                }
                name="firstName"
                placeholder={
                  translations.general.applyProgramPopup.firstNamePlaceholder
                }
              />
              <input
                onChange={onChangeField.bind(null, { fieldName: 'lastName' })}
                value={state.lastName}
                type="text"
                className={
                  'form-input form-input-large w-input ' + styles.input_field
                }
                name="lastName"
                placeholder={
                  translations.general.applyProgramPopup.lastNamePlaceholder
                }
              />
              <div>
                <label>
                  {translations.general.applyProgramPopup.countryTitle}
                </label>
                <div className={styles.countryInputWrap}>
                  <CountryInput
                    value={state.country}
                    onChange={onChangeField.bind(null, {
                      fieldName: 'country'
                    })}
                  />
                </div>
              </div>
              {/* <div>
                <label className={styles.titleWithNote}>
                  {translations.general.applyProgramPopup.phoneTitle}
                </label>
                <label className={styles.phoneNumberNote}>
                  {translations.general.applyProgramPopup.phoneNote}
                </label>
                <div className={styles.phoneInputWrap}>
                  <PhoneInput
                    value={state.phoneObj}
                    onPhoneChange={onChangeField.bind(null, {
                      fieldName: 'phoneObj'
                    })}
                    onCountryChange={onChangeField.bind(null, {
                      fieldName: 'phoneObj'
                    })}
                    placeholder={
                      translations.general.applyProgramPopup.phonePlaceholder
                    }
                  />
                </div>
              </div> */}
              <div>
                <label>
                  {translations.general.applyProgramPopup.fullTimeTitle}
                </label>
                <div className={styles.availabilityRadioBox}>
                  <div className={styles.radioItem}>
                    <span className={styles.radioText}>
                      {translations.general.applyProgramPopup.fullTimeYes}
                    </span>
                    <input
                      onChange={onChangeField.bind(null, {
                        fieldName: 'canDoFullTime'
                      })}
                      value={state.canDoFullTime}
                      type="radio"
                      name="canDoFullTime"
                      value="true"
                    />
                  </div>
                  <div className={styles.radioItem}>
                    <span className={styles.radioText}>
                      {translations.general.applyProgramPopup.fullTimeNo}
                    </span>
                    <input
                      onChange={onChangeField.bind(null, {
                        fieldName: 'canDoFullTime'
                      })}
                      value={state.canDoFullTime}
                      type="radio"
                      name="canDoFullTime"
                      value="false"
                    />
                  </div>
                </div>
              </div>
              {/* Submit btn */}
              <input
                onClick={onSubmitHandler}
                type="submit"
                value={translations.general.applyProgramPopup.submitBtnText}
                className={
                  'button button-large w-button ' +
                  styles.input_field +
                  ' ' +
                  styles.submitBtn
                }
              />
            </div>
            <div className={styles.checkOption}>
              <Checkbox
                color="primary"
                checked={state.accept_terms}
                onChange={setState.bind(null, {
                  accept_terms: !state.accept_terms
                })}
              />
              <span className={styles.terms}>
                {translations.general.termsCheckbox.termsText1}{' '}
                <a
                  className={styles.link}
                  target="_blank"
                  href={translations.general.termsAndConditionsLink}
                >
                  {translations.general.termsCheckbox.termsText2}
                </a>{' '}
                {translations.general.termsCheckbox.termsText3}{' '}
                <a
                  target="_blank"
                  className={styles.link}
                  href={translations.general.privacyPolicyLink}
                >
                  {translations.general.termsCheckbox.termsText4}
                </a>
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
